import axios from 'axios';

class EnvironmentConfigWrapper {
  constructor() {
    this.config = null;
    this.promise = null;
  }

  get() {
    if (this.config === null) throw new Error('Environment configuration not yet initialized');

    return this.config;
  }

  initialize() {
    if (!this.promise) {
      this.promise = axios({
        method: 'get',
        url: 'environment.json',
      }).then(({ data }) => {
        this.config = data;
      });
    }

    return this.promise;
  }
}

const envConfig = new EnvironmentConfigWrapper();
export default envConfig;
