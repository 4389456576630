export const themes = {
  LIGHT: 'light',
  DARK: 'dark',
  DARK_BLUE: 'dark-blue',
};

export const createUI = {
  ON: 'true',
  OFF: 'false',
  DISABLED: 'disabled',
};

export const sendAnalytics = {
  ON: 'true',
  OFF: 'false',
};

export const uploadFileFromPanel = {
  ON: 'true',
  OFF: 'false',
};

export const entitledVariations = {
  NOT_ENTITLED: 0,
  NOT_ASSIGNED: 1,
  ENTITLED: 2,
  UNKNOWN: 3,
};
