import 'core-js/es7/object';
import envConfig from 'environmentConfig';
import { sendAnalytics } from '../../constants/hostConfig';
import { getSendAnalytics } from '../urlUtil';

envConfig.initialize().then(() => {
  const nrConfig = envConfig.get().newRelic;

  if (getSendAnalytics() !== sendAnalytics.OFF) {
    NREUM.loader_config = {
      accountID: nrConfig.accountID,
      trustKey: nrConfig.trustKey,
      agentID: nrConfig.agentID,
      licenseKey: nrConfig.licenseKey,
      applicationID: nrConfig.applicationID,
    };
    NREUM.info = {
      beacon: 'bam.nr-data.net',
      errorBeacon: 'bam.nr-data.net',
      licenseKey: nrConfig.licenseKey,
      applicationID: nrConfig.applicationID,
      sa: 1,
    };
  }
});
