/** @type {Window & { newrelic?: Object }} */
const windowWithNewRelic = window;

class NewRelicHelper {
  constructor() {
    this.newRelicInstance = windowWithNewRelic.newrelic;
    this.productName = 'SharedViews Panel';
  }

  trackFeatureFlags(flags) {
    Object.keys(flags).forEach((flagKey) => {
      this.setCustomAttribute(`[FF]-${flagKey}`, flags[flagKey]);
    });
  }

  setReleaseTag() {
    // Required by NewRelic to track only errors
    // @ts-ignore
    this.newRelicInstance.addRelease(this.productName, RELEASE_TAG);
    // @ts-ignore
    this.newRelicInstance.setCustomAttribute(this.productName, RELEASE_TAG);
  }

  setCustomAttribute(attributeName, attributeValue) {
    this.newRelicInstance.setCustomAttribute(attributeName, attributeValue);
  }

  setInteractionName(interactionName) {
    this.newRelicInstance.interaction().setName(interactionName).save();
    this.newRelicInstance.interaction().end();
  }

  setCurrentRouteName(routeName) {
    this.newRelicInstance.setCurrentRouteName(routeName);
  }

  trackError(error) {
    this.newRelicInstance.noticeError(error);
  }
}

const newRelicHelper = new NewRelicHelper();
export default newRelicHelper;
